import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router/index'
import store from '@/store/index'

import "@/assets/nomral.css"
// 引入element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


// (默认使用英文) 引入中文
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

// 引入axios
import axios from "axios"

// 引入第三方阿里iconfont
import SvgIcon from './components/iconfont/SvgIcon.vue'
import './assets/iconfont/iconfont.js'


const app = createApp(App);

app.component('SvgIcon', SvgIcon)

app.use(ElementPlus, {
  locale: zhCn, // 中文
}).use(store).use(router).mount('#app')
