import { createRouter, createWebHashHistory } from 'vue-router'

// 加载子组件
const _HomeView = () => () => import(`@/views/HomeView.vue`);
const _import = (path) => () => import(`@/views/compView/${path}.vue`);

// 定义路由
const routes = [
  {
    path: '/',
    component: _HomeView(),
    redirect: { name: "search" },
    children: [
      {
        path: "search",
        name: "search",
        component: _import("search"),
        meta: { title: "搜索" }
      },
      {
        path: "acg",
        name: "acg",
        component: _import("acg"),
        meta: { title: "ACG" }
      },
      {
        path: "developer",
        name: "developer",
        component: _import("developer"),
        meta: { title: "编程" }
      },{
        path: "often",
        name: "often",
        component: _import("often"),
        meta: { title: "常用的网站" }
      }, {
        path: "randomPicture",
        name: "randomPicture",
        component: _import("randomPicture"),
        meta: { title: "每日一图" }
      }]
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})
// 路由守卫，路由跳转设置页面标题
router.beforeEach(async (to) => {
  if (to.meta.title) { document.title = `十三的导航-${to.meta.title} ` }
});

export default router
