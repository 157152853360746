<template>
    <svg aria-hidden="true" >
        <use :xlink:href="iconName" />
    </svg>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    iconName: {
        type: String,
        required: true
    },
})

const iconName = computed(() => `#${props.iconName}`)
</script>

<style scoped>
svg {

    vertical-align: top;
     display:inline
}
</style>
